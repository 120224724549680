import { DataCalculatorTypesEnum } from 'src/__apolloGenerated__/graphql';
import { create } from 'zustand';

export type ActiveDataCalculatorResponseType = {
  assetIdentifier: string;
  calculatorType: DataCalculatorTypesEnum;
};

export type DataCalculatorStoreType = {
  activeDataCalculatorResponse: ActiveDataCalculatorResponseType | null;
  setActiveDataCalculatorResponse: (
    dataCalculatorResponse: ActiveDataCalculatorResponseType
  ) => void;
  initialMeasurement: any;
  setInitialMeasurement: (measurement: any) => void;
  clearCurrentActive: () => void;
};

const useDataCalculatorStore = create<DataCalculatorStoreType>(
  (set) => ({
    activeDataCalculatorResponse: null,
    setActiveDataCalculatorResponse: (dataCalculatorResponse) =>
      set({ activeDataCalculatorResponse: dataCalculatorResponse }),
    initialMeasurement: null,
    setInitialMeasurement: (measurement) =>
      set({ initialMeasurement: measurement }),
    clearCurrentActive: () => {
      set({
        activeDataCalculatorResponse: null,
        initialMeasurement: null
      });
    }
  })
);

export default useDataCalculatorStore;
