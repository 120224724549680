import {
  Tooltip as TooltipBase,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger
} from 'src/components/shad-base/tooltip';

export default function TooltipIcon({
  trigger,
  text,
  side = 'top',
  renderTooltipContent
}: {
  trigger: React.ReactNode;
  text?: string;
  side?: 'left' | 'right' | 'top' | 'bottom';
  renderTooltipContent?: () => React.ReactNode;
}) {
  return (
    <TooltipProvider>
      <TooltipBase delayDuration={100}>
        <TooltipTrigger asChild>
          <div className="cursor-pointer">{trigger}</div>
        </TooltipTrigger>
        <TooltipContent side={side}>
          {renderTooltipContent ? renderTooltipContent() : text}
        </TooltipContent>
      </TooltipBase>
    </TooltipProvider>
  );
}
