import { Lock } from 'lucide-react';
import { Fragment, MouseEventHandler } from 'react';
import {
  DropdownMenu as DropdownMenuBase,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuPortal,
  DropdownMenuSeparator,
  DropdownMenuSub,
  DropdownMenuSubContent,
  DropdownMenuSubTrigger,
  DropdownMenuTrigger
} from 'src/components/shad-base/dropdown-menu';
import { getListItemKey } from 'src/utils/format';

export type ItemType = {
  name: string;
  key?: string;
  onSelect: MouseEventHandler;
  subItems?: ItemType[];
  isLocked?: boolean;
  disabled?: boolean;
  icon?: React.ReactNode;
};

export type GroupType = {
  items: ItemType[];
};

export type DropdownMenuProps = {
  items?: ItemType[];
  groups?: GroupType[];
  trigger?: React.ReactNode;
  menuHeader?: string;
  disabled?: boolean;
  renderItem?: (item: ItemType) => React.ReactNode;
  triggerClassName?: string;
  side?: 'top' | 'bottom' | 'left' | 'right';
};

export default function DropdownMenu({
  items,
  groups,
  trigger,
  menuHeader,
  disabled = false,
  renderItem,
  side = 'bottom',
  triggerClassName
}: DropdownMenuProps) {
  const renderDropdownItem = (item: ItemType, idx: number) => {
    return item.subItems ? (
      <DropdownMenuSub>
        <DropdownMenuSubTrigger>
          {renderItem ? renderItem(item) : <p>{item.name}</p>}
        </DropdownMenuSubTrigger>
        <DropdownMenuPortal>
          <DropdownMenuSubContent className="z-dropdown-menu-sub">
            {item.subItems.map((subItem, subIdx) => {
              return (
                <Fragment key={getListItemKey(subIdx)}>
                  {renderDropdownItem(subItem, subIdx)}
                </Fragment>
              );
            })}
          </DropdownMenuSubContent>
        </DropdownMenuPortal>
      </DropdownMenuSub>
    ) : (
      <DropdownMenuItem
        key={getListItemKey(idx)}
        onClick={(e) => {
          item.onSelect(e);
          e.stopPropagation();
        }}
        disabled={item.disabled}
      >
        {renderItem ? (
          renderItem(item)
        ) : (
          <div className="flex w-full flex-nowrap items-center justify-between">
            <div className="flex w-full grow flex-nowrap items-center">
              {item.icon && (
                <div className="mr-sm w-[18px] ">{item.icon}</div>
              )}
              <p>{item.name}</p>
            </div>
            {item.isLocked && (
              <Lock className="ml-sm h-icon w-icon" />
            )}
          </div>
        )}
      </DropdownMenuItem>
    );
  };
  return disabled ? (
    <div className="inline-block">{trigger}</div>
  ) : (
    <DropdownMenuBase>
      <DropdownMenuTrigger className={triggerClassName || undefined}>
        {trigger}
      </DropdownMenuTrigger>
      <DropdownMenuContent className="z-dropdown-menu" side={side}>
        {menuHeader && (
          <>
            <p className="p-sm font-bold">{menuHeader}</p>
            <DropdownMenuSeparator />
          </>
        )}
        {groups?.length > 0
          ? groups?.map((group, groupIdx) => {
              return (
                <Fragment key={getListItemKey(groupIdx)}>
                  {groupIdx !== 0 && <DropdownMenuSeparator />}
                  <DropdownMenuGroup>
                    {group?.items.map((item, index) => {
                      return (
                        <Fragment key={getListItemKey(index)}>
                          {renderDropdownItem(item, index)}
                        </Fragment>
                      );
                    })}
                  </DropdownMenuGroup>
                </Fragment>
              );
            })
          : items?.length > 0 &&
            items.map((item, index) => {
              return (
                <Fragment key={getListItemKey(index)}>
                  {renderDropdownItem(item, index)}
                </Fragment>
              );
            })}
      </DropdownMenuContent>
    </DropdownMenuBase>
  );
}
