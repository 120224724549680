import { Dialog } from 'src/components/shad-base/dialog';

import { useState } from 'react';
import { Button } from 'src/components/shad-base/button';
import {
  DialogContent,
  DialogFooter
} from 'src/components/shad-base/dialog';
import HubspotContactForm from 'src/components/marketing/HubspotContactForm';

export default function ContactSalesDialog({
  open,
  onOpenChange,
  selectedReport
}: {
  open: boolean;
  onOpenChange: (open: boolean) => void;
  selectedReport: string;
}) {
  const [hubspotFormOpen, setHubspotFormOpen] = useState(false);
  return (
    <>
      <Dialog open={open} onOpenChange={onOpenChange}>
        <DialogContent>
          <div className="flex flex-col flex-nowrap items-center">
            <img
              src={`/static/misc/${selectedReport}.png`}
              className="max-w-[150px]"
            />

            <p className="mt-md">
              Contact us to start your next {selectedReport} reporting
              period.
            </p>
          </div>
          <DialogFooter>
            <div className="mt-lg flex items-center">
              <Button onClick={() => setHubspotFormOpen(true)}>
                Request demo
              </Button>
            </div>
          </DialogFooter>
        </DialogContent>
      </Dialog>
      <Dialog
        open={hubspotFormOpen}
        onOpenChange={setHubspotFormOpen}
      >
        <DialogContent>
          <HubspotContactForm
            region="na1"
            portalId="46198686"
            formId="f9f357db-b6c7-4d26-bd47-da4f375683a7"
          />
        </DialogContent>
      </Dialog>
    </>
  );
}
