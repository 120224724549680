import { cn } from '@/lib/utils';
import { ExternalLink, Lock } from 'lucide-react';
import DropdownMenu from 'src/components/core/atoms/DropdownMenu';
import TooltipIcon from 'src/components/core/atoms/TooltipIcon';
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger
} from 'src/components/shad-base/accordion';
import useAppStore from 'src/hooks/store/useAppStore';

import useSettingsStore, {
  SettingsStoreType
} from 'src/hooks/store/useSettingsStore';
import { NavItemType } from 'src/layouts/Layout/SidebarNav';
import { getListItemKey } from 'src/utils/format';

export default function NavItem({ item }: { item: NavItemType }) {
  const { sidebarMinimized } = useAppStore((store) => ({
    sidebarMinimized: store.sidebarMinimized
  }));

  const { sideNavOpenState, setSideNavOpenState }: SettingsStoreType =
    useSettingsStore();

  const itemClassName = (isSelected, isDisabled) => {
    return cn(
      'flex items-center p-2 h-[30px] rounded-md ',
      isSelected
        ? isDisabled
          ? 'bg-muted/10 '
          : ' bg-primary/10 '
        : '',
      isDisabled
        ? ' text-muted '
        : ' cursor-pointer hover:bg-primary/10 transition-all '
    );
  };

  const handleClick = () => {
    if (!item.disabled) {
      item.onClick && item.onClick();
    }
  };

  const triggerClassName = item.disabled
    ? 'text-muted '
    : 'cursor-pointer rounded-sm p-1 transition-all hover:bg-primary/10 ';
  return sidebarMinimized ? (
    <TooltipIcon
      text={item.name}
      side="right"
      trigger={
        <div className="">
          {item.subItems ? (
            <DropdownMenu
              side="right"
              trigger={
                <div onClick={handleClick}>
                  {item.icon && (
                    <div
                      className={item.disabled ? 'text-muted' : ''}
                    >
                      {item.icon}
                    </div>
                  )}
                </div>
              }
              triggerClassName={triggerClassName}
              items={item.subItems.map((subItem) => ({
                name: subItem.name,
                onSelect: () => subItem.onClick(),
                isLocked: subItem.locked,
                disabled: subItem.disabled
              }))}
              menuHeader={item.name}
            />
          ) : (
            <div onClick={handleClick}>
              {item.icon && (
                <div className={triggerClassName}>{item.icon}</div>
              )}
            </div>
          )}
        </div>
      }
    />
  ) : (
    <div onClick={handleClick}>
      {item.subItems ? (
        <Accordion
          type="multiple"
          value={Object.keys(sideNavOpenState).reduce((acc, key) => {
            if (sideNavOpenState[key]) {
              acc.push(key);
            }
            return acc;
          }, [])}
          onValueChange={(value) => {
            setSideNavOpenState({
              inventory: value.includes('inventory'),
              organization: value.includes('organization'),
              tools: value.includes('tools'),
              mitigate: value.includes('mitigate'),
              report: value.includes('report'),
              settings: value.includes('settings')
            });
          }}
        >
          <AccordionItem value={item.key}>
            <AccordionTrigger
              className={
                'w-full ' +
                itemClassName(item.isSelected, item.disabled)
              }
            >
              <div className="flex items-center">
                {item.icon && (
                  <div className="mr-sm">{item.icon}</div>
                )}
                <p className="body2">{item.name}</p>
              </div>
            </AccordionTrigger>
            <AccordionContent className="mt-sm px-md pb-0">
              {item.subItems.map((subItem, subIdx) => {
                return (
                  <div
                    key={getListItemKey(subIdx)}
                    className={
                      (subIdx !== 0 ? 'mt-1 ' : '') +
                      itemClassName(
                        subItem.isSelected,
                        subItem.disabled
                      )
                    }
                    onClick={() => {
                      if (!subItem.disabled) {
                        subItem.onClick();
                      }
                    }}
                  >
                    <div className="flex w-full flex-nowrap items-center justify-between">
                      <p className="body2 text-nowrap">
                        {subItem.name}
                      </p>
                      {subItem.locked && (
                        <Lock className="mr-sm h-icon min-h-icon w-icon min-w-icon" />
                      )}
                    </div>
                  </div>
                );
              })}
            </AccordionContent>
          </AccordionItem>
        </Accordion>
      ) : (
        <div
          className={
            'flex flex-nowrap items-center justify-between ' +
            itemClassName(item.isSelected, item.disabled)
          }
        >
          <div className="flex items-center">
            {item.icon && <div className="mr-sm">{item.icon}</div>}
            <p className="body2">{item.name}</p>
          </div>
          <div className="flex items-center">
            {item.chip && (
              <div className="ml-sm flex h-[25px] items-center rounded-full bg-primary/15 px-md">
                <p className="body2 text-primary">{item.chip}</p>
              </div>
            )}
            {item.locked ? (
              <Lock className="ml-sm mr-sm h-icon w-icon" />
            ) : item.numItems ? (
              <div className="flex aspect-square items-center rounded-sm bg-secondary/10 px-2 text-center">
                <p className="body2 ">{item.numItems}</p>
              </div>
            ) : null}
            {item.external && (
              <ExternalLink className="scale-75 text-muted" />
            )}
          </div>
        </div>
      )}
    </div>
  );
}
