import { PanelLeftOpen } from 'lucide-react';
import { Button } from 'src/components/shad-base/button';
import useAppStore from 'src/hooks/store/useAppStore';
import { NavItemType } from 'src/layouts/Layout/SidebarNav';
import NavItem from 'src/layouts/Layout/SidebarNav/NavItem';

export default function MinimizedSidebarNav({
  isViewingOtherAccount,
  workspaceItems,
  priorityItems
}: {
  isViewingOtherAccount: boolean;
  workspaceItems: NavItemType[];
  priorityItems: NavItemType[];
}) {
  const { setSidebarMinimized } = useAppStore((store) => ({
    setSidebarMinimized: store.setSidebarMinimized
  }));

  return (
    <div
      className={
        ' h-full w-left-min-sidebar border-r ' +
        (isViewingOtherAccount ? 'bg-warning/50' : '')
      }
    >
      <div className="flex flex-col flex-nowrap items-center">
        {/* Expand Panel */}
        <div className="mt-sm">
          <Button
            variant="outline"
            size="icon"
            className="bg-muted/10 backdrop-blur hover:bg-muted/20"
            onClick={() => setSidebarMinimized(false)}
          >
            <PanelLeftOpen className="h-4 w-4" />
          </Button>
        </div>
        {/* Priority Items */}
        <div className="mt-md" />
        {priorityItems.map((item, idx) => (
          <div key={item.key} className={idx === 0 ? '' : 'mt-md'}>
            <NavItem item={item} />
          </div>
        ))}
        <div className="mx-md mb-sm mt-lg h-[1px] w-1/2 bg-border" />
        {/* Workspace Items */}
        {workspaceItems.map((item, idx) => (
          <div key={item.key} className={idx === 0 ? '' : 'mt-md '}>
            <NavItem key={item.key} item={item} />
          </div>
        ))}
      </div>
    </div>
  );
}
