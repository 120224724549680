import { gql, useQuery } from '@apollo/client';
import _ from 'lodash';
import {
  BarChartBig,
  Building,
  Calendar,
  ClipboardPen,
  FolderTree,
  Inbox,
  LogOut,
  Map,
  Network,
  PanelLeftClose,
  PieChart,
  Settings,
  Users,
  Wallet,
  Wrench
} from 'lucide-react';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import {
  AppSectionsEnum,
  EntityFragmentFragment,
  MeasurementFragmentFragment,
  ReconcileSubscriptionStatusInput,
  ReconcileSubscriptionStatusPayload,
  RecurringMeasurementsMissingSubsequentDataQueryQuery,
  SetActiveEntityInput,
  SetActiveEntityPayload,
  SidebarEntityQueryQuery,
  ToReviewAggregateMeasurementsFragmentFragment
} from 'src/__apolloGenerated__/graphql';
import DropdownMenu, {
  ItemType
} from 'src/components/core/atoms/DropdownMenu';
import Icon from 'src/components/core/atoms/Icon';
import Skeleton from 'src/components/core/atoms/Skeleton';
import { Button } from 'src/components/shad-base/button';
import {
  DropdownMenu as DropdownMenuBase,
  DropdownMenuCheckboxItem,
  DropdownMenuContent,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger
} from 'src/components/shad-base/dropdown-menu';

import dayjs from 'dayjs';
import { ROUTES, SUBSCRIPTION_PLANS } from 'src/config';
import useAppStore from 'src/hooks/store/useAppStore';
import useAuthPersistStore from 'src/hooks/store/useAuthPersistStore';
import useDataCalculatorStore, {
  DataCalculatorStoreType
} from 'src/hooks/store/useDataCalculatorStore';
import useFootprintStore, {
  FootprintStoreType,
  HeadType
} from 'src/hooks/store/useFootprintStore';
import useMeasureStore, {
  MeasureStoreType,
  UNLOCKED_COLUMN_KEY_SCHEMA_MATCH
} from 'src/hooks/store/useMeasureStore';
import useSettingsStore, {
  SettingsStoreType
} from 'src/hooks/store/useSettingsStore';
import useSubscriptionStore, {
  SubscriptionStoreType
} from 'src/hooks/store/useSubcriptionStore';
import useBackendMutation from 'src/hooks/useBackendMutation';
import { SET_ACTIVE_ENTITY } from 'src/hooks/useBackendMutation/mutations';
import usePaywall from 'src/hooks/usePaywall';
import ContactSalesDialog from 'src/layouts/Layout/SidebarNav/ContactSalesDialog';
import MinimizedSidebarNav from 'src/layouts/Layout/SidebarNav/MinimizedSidebarNav';
import NavItem from 'src/layouts/Layout/SidebarNav/NavItem';
import { getListItemKey } from 'src/utils/format';

export type NavItemType = {
  key: string;
  name: string;
  disabled?: boolean;
  onClick?: () => void;
  isSelected?: boolean;
  chip?: string;
  subItems?: {
    name: string;
    onClick: () => void;
    isSelected: boolean;
    locked?: boolean;
    icon?: React.ReactNode;
    disabled?: boolean;
  }[];
  icon?: React.ReactNode;
  numItems?: number;
  accordionOpen?: string;
  external?: boolean;
  locked?: boolean;
};

export type ToReviewMeasurementType =
  ToReviewAggregateMeasurementsFragmentFragment['toReviewAggregateMeasurements']['edges'][0]['node'];

export type ActiveEntityType = EntityFragmentFragment &
  SidebarEntityQueryQuery['activeEntity']['data']['tree'];

export default function SidebarNav() {
  const router = useRouter();
  const { signout } = useAuthPersistStore();
  const { activeSubscriptionTier }: SubscriptionStoreType =
    useSubscriptionStore();

  const { setSidebarMinimized, sidebarMinimized } = useAppStore(
    (store) => ({
      setSidebarMinimized: store.setSidebarMinimized,
      sidebarMinimized: store.sidebarMinimized
    })
  );
  const { sideNavOpenState } = useSettingsStore(
    (store: SettingsStoreType) => ({
      sideNavOpenState: store.sideNavOpenState
    })
  );

  const {
    isAuthorized: authorizedForMembers,
    requiredPlan: membersRequiredPlan,
    subtitleText: membersSubtitleText
  } = usePaywall('team');
  const {
    setRootNodeId,
    selectedStartDate,
    selectedEndDate,
    selectedFilters
  } = useMeasureStore((store: MeasureStoreType) => ({
    setRootNodeId: store.setRootNodeId,
    selectedStartDate: store.selectedStartDate,
    selectedEndDate: store.selectedEndDate,
    selectedFilters: store.selectedFilters
  }));

  const {
    setHeads,
    selectedTree,
    setSelectedTree,
    activeEntityName,
    setActiveEntityName,
    setToReviewMeasurementsTotal
  } = useFootprintStore((store: FootprintStoreType) => ({
    activeEntityName: store.activeEntityName,
    setActiveEntityName: store.setActiveEntityName,
    toReviewMeasurementsTotal: store.toReviewMeasurementsTotal,
    setToReviewMeasurementsTotal: store.setToReviewMeasurementsTotal,
    setHeads: store.setHeads,
    selectedTree: store.selectedTree,
    setSelectedTree: store.setSelectedTree
  }));

  const {
    setPaywallDialogOpen,
    setRequiredPlan,
    setSubtitleText
  }: SubscriptionStoreType = useSubscriptionStore();

  const { clearCurrentActive } = useDataCalculatorStore(
    (store: DataCalculatorStoreType) => ({
      clearCurrentActive: store.clearCurrentActive
    })
  );

  const [navItemAccordionOpen, setNavItemAccordionOpen] = useState<{
    [key in
      | 'organization'
      | 'inventory'
      | 'mitigate'
      | 'report']: boolean;
  }>({
    organization: true,
    inventory: true,
    mitigate: true,
    report: true
  });
  const [contactSalesDialogOpen, setContactSalesDialogOpen] =
    useState(false);
  const [selectedReport, setSelectedReport] = useState<
    'TCFD' | 'CDP' | null
  >(null);

  const {
    data,
    loading: loadingEntity,
    refetch: refetchActiveEntity,
    previousData
  } = useQuery<SidebarEntityQueryQuery>(
    gql`
      query SidebarEntityQuery(
        $startDate: DateTime
        $endDate: DateTime
      ) {
        activeEntity {
          errors {
            ...ErrorsFragment
          }
          ok
          data {
            ...EntityFragment
            tree {
              head {
                ...ToReviewAggregateMeasurementsFragment
              }
              branches {
                name
                identifier
                path
              }
            }
          }
        }
        entities {
          errors {
            ...ErrorsFragment
          }
          data {
            identifier
            name
            inPublicDirectory
            tree {
              identifier
              head {
                identifier
                name
              }
            }
          }
        }
        user {
          errors {
            ...ErrorsFragment
          }
          data {
            isSuperuser
            identifier
            profile {
              firstName
              lastName
            }
            emailAddress
            activeAccount {
              isOwner
              user {
                isSuperuser
                identifier
              }
              activeReportingPeriod {
                identifier
                startDate
                endDate
              }
              appSectionPermissions {
                appSections {
                  appSection
                  queries
                  mutations
                }
                sectionsAreLocked
              }
              measureViewColumns
            }
          }
        }
      }
    `,
    {
      variables: {
        startDate: dayjs(selectedStartDate).toDate(),
        endDate: dayjs(selectedEndDate).toDate(),
        states: selectedFilters
      },
      // If one query returns an error, don't prevent the others from returning data
      errorPolicy: 'all'
    }
  );

  const user = data?.user?.data;
  const entities = data?.entities?.data;
  const activeEntity = data?.activeEntity?.data as ActiveEntityType;
  const isOwner = user?.activeAccount?.isOwner;
  const activeReportingPeriod =
    user?.activeAccount?.activeReportingPeriod;
  const isSuperuser = user?.activeAccount?.user?.isSuperuser;

  const previousDataEntity = previousData?.activeEntity
    ?.data as ActiveEntityType;

  const appSectionPermissions =
    user?.activeAccount?.appSectionPermissions;

  const getIsAppSectionAvailable = (appSection: AppSectionsEnum) => {
    if (appSectionPermissions?.sectionsAreLocked) {
      // If sections are locked, it's only available if it's not in the list
      return !appSectionPermissions?.appSections.some(
        (section) => section.appSection === appSection
      );
    } else {
      // If sections are not locked, it's available if it's in the list
      return appSectionPermissions?.appSections.some(
        (section) => section.appSection === appSection
      );
    }
  };

  const { data: missingMeasurementData } =
    useQuery<RecurringMeasurementsMissingSubsequentDataQueryQuery>(
      gql`
        query RecurringMeasurementsMissingSubsequentDataQuery {
          recurringMeasurementsMissingSubsequentData {
            ok
            errors {
              ...ErrorsFragment
            }
            data {
              id
              identifier
            }
          }
        }
      `,
      {
        fetchPolicy: 'network-only',
        skip: !getIsAppSectionAvailable(
          AppSectionsEnum.CarbonInventory
        )
      }
    );

  const heads =
    activeEntity?.tree?.heads?.length > 0
      ? activeEntity?.tree?.heads
      : null;
  const head = activeEntity?.tree
    ?.head as ToReviewAggregateMeasurementsFragmentFragment;
  const toReviewMeasurements =
    head?.toReviewAggregateMeasurements?.edges.map(
      (edge) => edge.node
    ) as ToReviewMeasurementType[];

  const { mutate: setActiveEntity } = useBackendMutation<
    { input: SetActiveEntityInput },
    SetActiveEntityPayload
  >({
    mutation: SET_ACTIVE_ENTITY,
    callbacks: {
      onSuccess: (data) => {
        useMeasureStore.getState().setActiveNode({
          nodeId: null,
          type: null,
          path: null
        });
        useFootprintStore
          .getState()
          .setHeads(data?.entity?.tree?.heads as HeadType[]);

        if (router.pathname !== ROUTES.ROADMAP.LIST) {
          router.push(ROUTES.ROADMAP.LIST);
        }
        refetchActiveEntity();
      }
    }
  });
  const { mutate: reconcileSubscriptionStatus } = useBackendMutation<
    { input: ReconcileSubscriptionStatusInput },
    ReconcileSubscriptionStatusPayload
  >({
    mutation: gql`
      mutation ReconcileSubscriptionStatus(
        $input: ReconcileSubscriptionStatusInput!
      ) {
        reconcileSubscriptionStatus(input: $input) {
          ok
          user {
            ...UserFragment
          }
          errors {
            ...ErrorsFragment
          }
        }
      }
    `
  });
  let isViewingOtherAccount = false;

  //if the userId attached to the active account is not the same as the resolved userId,
  //and the current user is a superuser, then they are viewing another user's account
  if (
    user?.activeAccount?.user?.identifier !== user?.identifier &&
    user?.isSuperuser
  ) {
    isViewingOtherAccount = true;
  }

  useEffect(() => {
    const newNavItemAccordionOpen = { ...navItemAccordionOpen };
    if (
      router.pathname.startsWith('/organization') &&
      navItemAccordionOpen['organization'] === false
    ) {
      newNavItemAccordionOpen['organization'] = true;
    }
    setNavItemAccordionOpen(newNavItemAccordionOpen);
  }, []);

  // // On first load select first tree
  useEffect(() => {
    if (heads) {
      const head = heads[0];
      setRootNodeId(heads[0]?.identifier);
      if (!selectedTree?.identifier) {
        setSelectedTree({
          path: head?.path,
          identifier: head?.identifier,
          name: head?.name,
          userPermissions: head?.userPermissions
        });
        setHeads(
          heads.map((head) => {
            return {
              path: head?.path,
              identifier: head?.identifier,
              name: head?.name,
              userPermissions: head?.userPermissions
            };
          })
        );
      }
    }
    if (activeEntity?.billing?.subscriptionReconciliationRequired) {
      reconcileSubscriptionStatus({
        variables: {
          input: {
            harmonize: true
          }
        }
      });
    }
    if (activeEntity) {
      setActiveEntityName(activeEntity?.name);
      setToReviewMeasurementsTotal(
        head?.toReviewAggregateMeasurements?.totalCount
      );
    }
  }, [heads, activeEntity]);

  useEffect(() => {
    useMeasureStore
      .getState()
      .setUnlockedColumnKeys(
        user?.activeAccount?.measureViewColumns?.map(
          (key: string) => UNLOCKED_COLUMN_KEY_SCHEMA_MATCH[key]
        )
      );
  }, [user]);

  const getToolsNavItem = (page: string) => {
    let disabled = false;
    if (page === 'scope1') {
      // Disabled if no scope 1 tools are available
      disabled =
        !getIsAppSectionAvailable(
          AppSectionsEnum.LocationScope_1Calculators
        ) &&
        !getIsAppSectionAvailable(
          AppSectionsEnum.VehicleScope_1Calculators
        ) &&
        !getIsAppSectionAvailable(
          AppSectionsEnum.EquipmentScope_1Calculators
        );
    }
    if (page === 'scope2') {
      // Disabled if no scope 2 tools are available
      disabled = !getIsAppSectionAvailable(
        AppSectionsEnum.LocationScope_2Calculators
      );
    }
    if (page === 'scope3') {
      // Disabled if no scope 3 tools are available
      disabled = !getIsAppSectionAvailable(
        AppSectionsEnum.LocationScope_2Calculators
      );
    }

    return {
      name: _.startCase(page),
      isSelected: router.pathname === '/tools/' + page,
      onClick: () => {
        if (router.pathname !== page) {
          router.push('/tools/' + page);
        }

        // If there is an active data calculator already set somewhere else, clear it
        if (page !== 'overview') {
          clearCurrentActive();
        }
      },
      disabled
    };
  };

  const getOrganizationNavItem = (page: string) => {
    const appSection = _.snakeCase(
      page
    ).toUpperCase() as AppSectionsEnum;

    return {
      name: _.startCase(page),
      isSelected: router.pathname === '/organization/' + page,
      onClick: () => {
        if (router.pathname !== page) {
          router.push('/organization/' + page);
        }
      },
      disabled: appSection && !getIsAppSectionAvailable(appSection)
    };
  };

  const getMitigateNavItem = (page: string) => {
    const appSection = _.snakeCase(
      page
    ).toUpperCase() as AppSectionsEnum;
    return {
      name: _.startCase(page),
      isSelected: router.pathname === '/mitigate/' + page,
      onClick: () => {
        if (router.pathname !== page) {
          router.push('/mitigate/' + page);
        }
      },
      disabled: appSection
        ? !getIsAppSectionAvailable(appSection)
        : false
    };
  };

  const getReportsNavItem = (page: string) => {
    let disabled = false;
    if (page === 'ESR') {
      disabled = !getIsAppSectionAvailable(AppSectionsEnum.Esr);
    }
    return {
      name: _.startCase(page),
      isSelected: router.pathname === '/report/' + page,
      onClick: () => {
        if (page === 'TCFD' || page === 'CDP') {
          setContactSalesDialogOpen(true);
          setSelectedReport(page as 'TCFD' | 'CDP');
          return;
        }
        if (router.pathname !== page) {
          router.push('/report/' + page);
        }
      },
      locked: page === 'TCFD' || page === 'CDP',
      disabled
    };
  };

  const missingMeasurements = missingMeasurementData
    ?.recurringMeasurementsMissingSubsequentData
    ?.data as MeasurementFragmentFragment[];

  let inboxItems = 0;
  if (missingMeasurements?.length > 0) {
    inboxItems += 1;
  }
  if (toReviewMeasurements?.length > 0) {
    inboxItems += 1;
  }

  const settingsItems: ItemType[] = [
    {
      name: 'General',
      key: 'profile',
      onSelect: () => {
        router.push(ROUTES.SETTINGS.OVERVIEW);
      },
      icon: <Settings className="h-4 w-4" />
    },
    {
      name: 'Team',
      key: 'team',
      isLocked: !authorizedForMembers,
      onSelect: () => {
        if (authorizedForMembers) {
          router.push(ROUTES.SETTINGS.TEAM);
        } else {
          setPaywallDialogOpen(true);
          setRequiredPlan(membersRequiredPlan);
          setSubtitleText(membersSubtitleText);
        }
      },
      icon: <Users className="h-4 w-4" />
    }
  ];

  if (isOwner) {
    settingsItems.push({
      name: 'Billing',
      key: 'billing',
      onSelect: () => {
        router.push(ROUTES.SETTINGS.BILLING);
      },
      icon: <Wallet className="h-4 w-4" />
    });
  }

  settingsItems.push({
    name: 'Sign out',
    key: 'signout',
    icon: <LogOut className="h-4 w-4" />,
    onSelect: () => {
      signout();
    }
  });

  const priorityItems: NavItemType[] = [
    {
      key: 'roadmaps',
      name: 'Roadmaps',
      isSelected: router.pathname.startsWith(ROUTES.ROADMAP.LIST),
      onClick: () => {
        if (router.pathname !== ROUTES.ROADMAP.LIST) {
          router.push(ROUTES.ROADMAP.LIST);
        }
      },
      icon: <Map className="h-icon w-icon" />
    }
  ];
  if (getIsAppSectionAvailable(AppSectionsEnum.CarbonInventory)) {
    priorityItems.unshift({
      key: 'inbox',
      name: 'Inbox',
      numItems: inboxItems,
      isSelected: router.pathname === ROUTES.INBOX,
      onClick: () => {
        if (router.pathname !== ROUTES.INBOX) {
          router.push(ROUTES.INBOX);
        }
      },
      icon: <Inbox className="h-icon w-icon" />
    });
  }

  if (isSuperuser) {
    priorityItems.push({
      key: 'aes',
      name: 'AES Template Editor',
      isSelected: router.pathname.includes('/aes-template-editor'),
      onClick: () => {
        if (!router.pathname.includes('/aes-template-editor')) {
          router.push(ROUTES.AES_TEMPLATE_EDITOR.LIST);
        }
      },
      icon: <Network className="h-icon w-icon" />
    });
  }

  const workspaceItems: NavItemType[] = [
    {
      key: 'dashboard',
      name: 'Dashboard',
      isSelected: router.pathname === ROUTES.DASHBOARD,
      onClick: () => {
        if (router.pathname !== ROUTES.DASHBOARD) {
          router.push(ROUTES.DASHBOARD);
        }
      },
      disabled: !getIsAppSectionAvailable(AppSectionsEnum.General),
      icon: <PieChart className="h-icon w-icon" />
    },
    {
      key: 'inventory',
      name: 'Carbon Inventory',
      isSelected: router.pathname === ROUTES.INVENTORY,
      onClick: () => {
        if (router.pathname !== ROUTES.INVENTORY) {
          router.push(ROUTES.INVENTORY);
        }
      },
      disabled: !getIsAppSectionAvailable(
        AppSectionsEnum.CarbonInventory
      ),
      icon: <FolderTree className="h-icon w-icon" />
    },
    {
      key: 'organization',
      name: 'Organization',
      subItems: [
        getOrganizationNavItem('locations'),
        getOrganizationNavItem('vehicles'),
        getOrganizationNavItem('equipment')
      ],
      icon: <Building className="h-icon w-icon" />,
      disabled:
        !getIsAppSectionAvailable(AppSectionsEnum.Locations) &&
        !getIsAppSectionAvailable(AppSectionsEnum.Vehicles) &&
        !getIsAppSectionAvailable(AppSectionsEnum.Equipment),
      // Propagate selection to parent if any subitem is selected
      isSelected:
        router.pathname.startsWith('/organization') &&
        !sideNavOpenState['organization'],
      accordionOpen: sideNavOpenState['organization']
        ? 'organization'
        : null
    },
    {
      key: 'tools',
      name: 'Tools',
      subItems: [
        getToolsNavItem('scope1'),
        getToolsNavItem('scope2'),
        getToolsNavItem('scope3')
      ],
      icon: <Wrench className="h-icon w-icon" />,
      disabled:
        !getIsAppSectionAvailable(
          AppSectionsEnum.LocationScope_1Calculators
        ) &&
        !getIsAppSectionAvailable(
          AppSectionsEnum.VehicleScope_1Calculators
        ) &&
        !getIsAppSectionAvailable(
          AppSectionsEnum.EquipmentScope_1Calculators
        ),
      // Propagate selection to parent if any subitem is selected
      isSelected:
        router.pathname.startsWith('/tools') &&
        !sideNavOpenState['tools'],
      accordionOpen: sideNavOpenState['tools'] ? 'tools' : null
    },
    {
      key: 'mitigate',
      name: 'Mitigate',
      subItems: [
        getMitigateNavItem('target'),
        getMitigateNavItem('strategies'),
        getMitigateNavItem('actions'),
        getMitigateNavItem('incentives')
      ],
      disabled:
        !getIsAppSectionAvailable(AppSectionsEnum.Actions) &&
        !getIsAppSectionAvailable(AppSectionsEnum.Incentives) &&
        !getIsAppSectionAvailable(AppSectionsEnum.Target) &&
        !getIsAppSectionAvailable(AppSectionsEnum.Strategies),
      icon: <BarChartBig className="h-icon w-icon" />,
      // Propagate selection to parent if any subitem is selected
      isSelected:
        router.pathname.startsWith('/mitigate') &&
        !sideNavOpenState['mitigate'],
      accordionOpen: sideNavOpenState['mitigate'] ? 'mitigate' : null
    },
    {
      key: 'report',
      name: 'Report',
      subItems: [
        getReportsNavItem('ESR'),
        getReportsNavItem('TCFD'),
        getReportsNavItem('CDP')
      ],
      disabled: !getIsAppSectionAvailable(AppSectionsEnum.Esr),
      icon: <ClipboardPen className="h-icon w-icon" />,
      // Propagate selection to parent if any subitem is selected
      isSelected:
        router.pathname.startsWith('/report') &&
        !sideNavOpenState['report'],
      accordionOpen: sideNavOpenState['report'] ? 'report' : null
    }
  ];

  const fiscalYear = activeReportingPeriod
    ? dayjs(activeReportingPeriod?.startDate).year()
    : null;
  const isFiscalYearAvailable = getIsAppSectionAvailable(
    AppSectionsEnum.ReportingPeriods
  );
  return (
    <>
      {sidebarMinimized ? (
        <MinimizedSidebarNav
          isViewingOtherAccount={isViewingOtherAccount}
          workspaceItems={workspaceItems}
          priorityItems={priorityItems}
        />
      ) : (
        <div
          id="contact-sales-link"
          className="relative flex h-full w-left-sidebar min-w-left-sidebar max-w-left-sidebar flex-col flex-nowrap border-r bg-background"
        >
          <div className="h-full max-h-screen w-full overflow-y-auto p-md">
            {/* Entity Name */}
            {isViewingOtherAccount && (
              <div className="absolute left-0 top-0 flex h-[30px] w-full items-center justify-center bg-warning/50">
                <p className="body2 font-bold text-warning-foreground">
                  Superuser View
                </p>
              </div>
            )}
            <div
              className={
                'mb-md w-full ' +
                (isViewingOtherAccount ? 'mt-lg' : '')
              }
            >
              <div className="flex max-w-full flex-nowrap justify-between ">
                <div className="flex max-w-full flex-nowrap ">
                  <div
                    className="cursor-pointer"
                    onClick={() => router.push(ROUTES.ROADMAP.LIST)}
                  >
                    <Icon size="xs" />
                  </div>
                  {!activeEntityName &&
                  loadingEntity &&
                  !previousData ? (
                    <div className="ml-sm">
                      <Skeleton height={20} width={150} />
                    </div>
                  ) : entities?.length > 1 ? (
                    <DropdownMenuBase>
                      <DropdownMenuTrigger className="">
                        <div className="pl-sm">
                          <div className="flex items-center justify-start ">
                            <p
                              className={
                                ' max-w-[150px] truncate text-nowrap font-bold '
                              }
                            >
                              {activeEntityName}
                            </p>
                          </div>
                        </div>
                      </DropdownMenuTrigger>
                      <DropdownMenuContent className="z-[600]">
                        <DropdownMenuLabel>
                          Switch Organization
                        </DropdownMenuLabel>
                        <DropdownMenuSeparator />
                        {entities.map((entity, index) => {
                          return (
                            <DropdownMenuCheckboxItem
                              key={getListItemKey(index)}
                              checked={
                                activeEntity?.identifier ===
                                entity.identifier
                              }
                              onCheckedChange={() => {
                                setActiveEntity({
                                  variables: {
                                    input: {
                                      entityIdentifier:
                                        entity.identifier
                                    }
                                  }
                                });
                              }}
                            >
                              <div className="flex flex-nowrap items-center">
                                <p>{entity.name}</p>
                              </div>
                            </DropdownMenuCheckboxItem>
                          );
                        })}
                      </DropdownMenuContent>
                    </DropdownMenuBase>
                  ) : (
                    <div className={'ml-sm w-full max-w-[130px] '}>
                      <p
                        className={
                          'min-w-0 text-wrap ' +
                          (activeEntityName ||
                          previousDataEntity?.name
                            ? ' font-bold '
                            : ' text-muted ')
                        }
                      >
                        {loadingEntity && previousDataEntity
                          ? previousDataEntity?.name
                          : activeEntityName || 'Company'}
                      </p>
                    </div>
                  )}
                </div>
                <div className="absolute right-1 top-1">
                  <Button
                    variant="outline"
                    className="bg-muted/10 backdrop-blur hover:bg-muted/20"
                    size="icon"
                    onClick={() => setSidebarMinimized(true)}
                  >
                    <PanelLeftClose className="h-4 w-4" />
                  </Button>
                </div>
              </div>
            </div>

            {/* Priority Items */}
            {priorityItems.map((item, idx) => {
              return (
                <div
                  className={idx === 0 ? '' : 'mt-sm'}
                  key={getListItemKey(idx)}
                >
                  <NavItem item={item} />
                </div>
              );
            })}
            {/* Workspace Items */}
            <div className="mb-sm mt-lg flex flex-nowrap items-center justify-between px-sm">
              <p className="body2  text-muted">Workspace</p>
              {!loadingEntity &&
                isFiscalYearAvailable &&
                fiscalYear && (
                  <div
                    onClick={() =>
                      router.push(ROUTES.SETTINGS.OVERVIEW)
                    }
                    className="flex cursor-pointer items-center rounded-md bg-secondary/10 p-1 px-2 transition-all hover:bg-secondary/20"
                  >
                    <Calendar className="mr-sm h-4 w-4 text-secondary-foreground" />
                    <p className="body2 text-secondary-foreground">
                      {fiscalYear}
                    </p>
                  </div>
                )}
            </div>
            {workspaceItems.map((item, idx) => {
              return (
                <div
                  className={idx === 0 ? '' : 'mt-sm'}
                  key={getListItemKey(idx)}
                >
                  <NavItem item={item} />
                </div>
              );
            })}

            {/* Extra scroll space  */}
            <div className="py-lg" />
          </div>

          <div className="absolute bottom-0 flex h-[60px] w-full bg-background"></div>
          {/* Settings Dropdown */}
          <div className="absolute bottom-4 left-4 flex items-center justify-center rounded-full">
            <DropdownMenu
              items={settingsItems}
              trigger={
                <Button
                  variant="outline"
                  size="icon"
                  className="bg-card hover:bg-muted/20"
                >
                  <Settings className="h-4 w-4" />
                </Button>
              }
            />
          </div>

          {/* Plan Chip */}
          <div className="absolute bottom-4 right-4">
            {loadingEntity ? (
              <div className="ml-sm">
                <Skeleton
                  height={30}
                  width={80}
                  style={{ borderRadius: 5 }}
                />
              </div>
            ) : (
              activeEntity && (
                <div
                  className="p-s ml-sm flex h-[30px] cursor-pointer items-center rounded-full bg-card px-md hover:bg-muted/20"
                  onClick={() => {
                    router.push(ROUTES.SETTINGS.BILLING);
                  }}
                >
                  <p className="body2">
                    {SUBSCRIPTION_PLANS[activeSubscriptionTier]?.name}
                  </p>
                </div>
              )
            )}
          </div>
        </div>
      )}
      {/* Contact Sales */}
      <ContactSalesDialog
        open={contactSalesDialogOpen}
        onOpenChange={setContactSalesDialogOpen}
        selectedReport={selectedReport}
      />
    </>
  );
}
